import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useUser, useSupabaseClient } from '@supabase/auth-helpers-react';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';

import Logo from '@/components/icons/logo';
import { getURL } from '@/utils/helpers';
import { Progress } from '@/components/ui/progress';

const SignIn = () => {
  const router = useRouter();
  const user = useUser();
  const supabaseClient = useSupabaseClient();

  useEffect(() => {
    if (user) {
      router.replace('/');
    }
  }, [user]);

  return (
    <section>
      {!user ? (
        <div className="flex flex-col justify-center items-center min-h-screen max-w-lg p-3 m-auto w-80 align-middle">
          <div>
            <div className="flex justify-center">
              <Logo />
            </div>
            <h6 className="leading-7 mt-2 text-center text-gray-400">
              Harness the power of AI to easily create impactful thumbnails.
            </h6>
          </div>
          <Auth
            supabaseClient={supabaseClient}
            providers={['google']}
            onlyThirdPartyProviders={true}
            redirectTo={getURL()}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    defaultButtonText: 'white',
                    defaultButtonBackground: '#334155',
                    defaultButtonBorder: '#334155',
                    defaultButtonBackgroundHover: '#64748b'
                  }
                }
              }
            }}
            theme="default"
          />
        </div>
      ) : (
        <Progress />
      )}
    </section>
  );
};
export default SignIn;
