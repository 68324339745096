export const getURL = () => {
  let url =
    process?.env?.NEXT_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
    process?.env?.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
    'http://localhost:3000/';
  // Make sure to include `https://` when not localhost.
  url = url.includes('http') ? url : `https://${url}`;
  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === '/' ? url : `${url}/`;
  return url;
};

export const toDateTime = (secs: number) => {
  var t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

export const extractListAndConvertToCSV = (list: string): string[] => {
  // Split the list by line breaks
  const lines = list.split('\n');

  // Extract titles and store them in an array
  const titles: string[] = lines
    .map((line) => {
      const titleMatch = line.match(/\d+\.\s*"(.+?)"/);
      if (titleMatch && titleMatch[1]) {
        return titleMatch[1];
      } else {
        return '';
      }
    })
    .filter((title) => title.length > 0);

  return titles;
};

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const debounce = (
  func: (...args: any[]) => void,
  wait: number | undefined
) => {
  let timeout: string | number | NodeJS.Timeout | undefined;

  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      // @ts-ignore
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};
